import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { JsonInterceptor2, axiosForMultipart } from "../../../utils/JsonInterceptor";
import { AuthType } from "./type";
import axios from "axios";

export const signin = (body, countryCode) => async (dispatch) => {
  dispatch({ type: AuthType.USER_SIGNIN_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.login}`;
    const { data } = await JsonInterceptor2(countryCode).post(`${apiEndpoint}`, body);
    if (body.flag === "login") {
      localStorage.setItem("adminInfo", JSON.stringify(data.data));
    }
    if (body.flag === "sendOTP" || body.flag === "regOTP") {
      return dispatch({ type: AuthType.USER_OTP_SUCCESS, payload: data });
    } else {
      return dispatch({ type: AuthType.USER_SIGNIN_SUCCESS, payload: data });
    }
  } catch (error) {
    return dispatch({
      type: AuthType.USER_SIGNIN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.data.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.LOG_OUT
    });
  } catch (error) {
    return error;
  }
};

//authCheck
export const authCheck = () => async (dispatch) => {
  try {
    dispatch({ type: AuthType.AUTH_CHEK_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheck}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    await localStorage.setItem("adminInfo", JSON.stringify(data.data));
    await localStorage.setItem("country-code", data.data.countryCode);
    return dispatch({
      type: AuthType.AUTH_CHEK_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.AUTH_CHEK_FAIL, error: message });
  }
};

//authRefreshCaptcha
export const authRefreshCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.REFRESH_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authRefreshCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.REFRESH_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.REFRESH_CAPTCHA_FAIL, error: message });
  }
};

//authCheckCaptcha
export const authCheckCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.CHEK_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheckCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.CHEK_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.CHEK_CAPTCHA_FAIL, error: message });
  }
};

// confirm password
export const addConfirmPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.PASSWORD_CHANGE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.changePassword}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.PASSWORD_CHANGE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    return dispatch({ type: AuthType.PASSWORD_CHANGE_FAIL, error: message });
  }
};

// registeration
export const registeration = (body) => async (dispatch) => {
  dispatch({ type: AuthType.USER_REGISTER_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.register}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    // if (body.flag === "login") {
    localStorage.setItem("adminInfo", JSON.stringify(data.data));
    // }
    return dispatch({ type: AuthType.USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    return dispatch({
      type: AuthType.USER_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_PROFILE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getProfile}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.GET_PROFILE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.GET_PROFILE_FAIL, error: message });
  }
};

export const getAddressByZip = (params) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_ADDRESS_REQUEST });
    let apiEndpoint = `https://www.promitool.com/api/v1/zip-data/${params.countryCode}/${params.zip}`;
    const { data } = await axios.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.GET_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    toast.error(error);
    return dispatch({ type: AuthType.GET_ADDRESS_FAIL, error: error });
  }
};

export const updateProfile = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.UPDATE_PROFILE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateProfile}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.UPDATE_PROFILE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.UPDATE_PROFILE_FAIL, error: message });
  }
};

export const resendVerifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.RESEND_VERIFY_EMAIL_REQUEST });
    let apiEndpoint = `${API_END_POINTS.resendVerifyEmail}`;
    const { data } = await axiosForMultipart.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.RESEND_VERIFY_EMAIL_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.RESEND_VERIFY_EMAIL_FAIL, error: message });
  }
};
export const verifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.VERIFY_EMAIL_REQUEST });
    let apiEndpoint = `${API_END_POINTS.verifyEmail}`;
    const { data } = await axiosForMultipart.get(`${apiEndpoint}/${body}`);
    return dispatch({
      type: AuthType.VERIFY_EMAIL_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: AuthType.VERIFY_EMAIL_FAIL, error: message });
  }
};

// registeration
export const autoLogin = (body) => async (dispatch) => {
  dispatch({ type: AuthType.AUTO_LOGIN_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.getAutoLogin}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);

    await localStorage.setItem("adminInfo", JSON.stringify(data.data));
    return dispatch({ type: AuthType.AUTO_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    return dispatch({
      type: AuthType.AUTO_LOGIN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

// payment add
export const savePayment = (body) => async (dispatch) => {
  dispatch({ type: AuthType.SAVE_PAYMENT_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.savePayment}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({ type: AuthType.SAVE_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.SAVE_PAYMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

// get payment
export const getPayment = (id) => async (dispatch) => {
  dispatch({ type: AuthType.GET_PAYMENT_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.getPayment}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, id);
    return dispatch({ type: AuthType.GET_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.SAVE_PAYMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

// payment update
export const updatePayment = (body) => async (dispatch) => {
  dispatch({ type: AuthType.UPDATE_PAYMENT_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.updatePayment}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({ type: AuthType.UPDATE_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.UPDATE_PAYMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

// Notification modal list
export const notificationList = () => async (dispatch) => {
  dispatch({ type: AuthType.NOTIFICATION_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.notificationList}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({ type: AuthType.NOTIFICATION_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.NOTIFICATION_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

// Notification modal list
export const notificationRead = (body) => async (dispatch) => {
  dispatch({ type: AuthType.NOTIFICATION_READ_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.notificationRead}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({ type: AuthType.NOTIFICATION_READ_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.NOTIFICATION_READ_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};
// verify update
export const verifyPayment = (body) => async (dispatch) => {
  dispatch({ type: AuthType.VERIFY_PAYMENT_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.verifyPayment}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({ type: AuthType.VERIFY_PAYMENT_SUCCESS, payload: data });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({
      type: AuthType.VERIFY_PAYMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};
