import moment from "moment-timezone";
import countryCountryCode from "./countryCode.json";
import timezoneCountry from "./timezoneCountry.json";
import { CanadaFlagIcon, CheckFillIcon, ExclamationFillIcon, IndiaFlagIcon, USAFlagIcon } from "../components/Icons";
import { API_ROOT } from "./config";
// import { useTheme } from "@emotion/react";
// import { tokens } from "../theme";

// const theme = useTheme();
// const colors = tokens(theme.palette.mode);
export function normalizeString(input) {
  if (typeof input !== "string") {
    return;
  }
  let result = input.replace(/\s+/g, "-");

  result = result.replace(/[^a-zA-Z0-9-]/g, "-");
  result = result.replace(/-+/g, "-");
  result = result.replace(/^-|-$/g, "");

  return result.toLowerCase();
}

export const stringToSlug = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll(".", "-")
    .replaceAll("/", "-")
    .replaceAll("&", "-")
    .replaceAll(/^-+|-+$/g, "");
};
export const stringFormat = (str) => {
  return str.trim().replaceAll("_", " ");
};

export const stringToCountrySlug = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replaceAll(" ", "")
    .replaceAll(".", "")
    .replaceAll("/", "")
    .replaceAll("&", "")
    .replaceAll(/^-+|-+$/g, "");
};

export const isTodayOrTomorrowOrWeekend = (date) => {
  const today = moment().startOf("day");
  const tomorrow = today.clone().add(1, "day");
  const endOfWeek = today.clone().endOf("week");
  const givenMoment = moment(date);
  const pastString = moment(date).startOf("day").fromNow();
  let pastEvent = pastString.includes("ago");
  const dateToCheck = moment(date).startOf("day");
  if (dateToCheck.isSame(today, "day")) {
    return "Today";
  }
  if (dateToCheck.isSame(tomorrow, "day")) {
    return "Tomorrow";
  }
  if (dateToCheck.isBetween(tomorrow, endOfWeek, "day", "[]")) {
    return "This Week";
  }
  if (pastEvent) {
    return "Past";
  }
  if (givenMoment.month() === today) {
    return "This Month";
  } else {
    return "Future";
  }
  // return "";
};

export function youtubeId(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export function numberRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}
export function dayType(type) {
  if (type === "fixedDay") {
    return "Fixed Day";
  } else if (type === "anyOneDay") {
    return "Any One Day";
  } else {
    return "multiDay";
  }
}
export function isAmount(value) {
  return /^\d*(\.\d{0,2})?$/.test(value);
}

export function countryList() {
  return ["US", "IN", "CA"];
}
export function countryFilter(countryCode) {
  switch (countryCode) {
    case "US":
      return { phoneCode: "+1", currency: "$", icon: <USAFlagIcon /> };
    case "IN":
      return { phoneCode: "+91", currency: "₹", icon: <IndiaFlagIcon /> };
    case "CA":
      return { phoneCode: "+1", currency: "$", icon: <CanadaFlagIcon /> };
    default:
      return { phoneCode: "+1", currency: "$", icon: <USAFlagIcon /> };
  }
}

export function BankVerificationStatus(status) {
  switch (status) {
    case "pending":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color={"#f3863e"} />,
        color: "#f3863e",
        value: "pending",
        disabled: true,
        name: "Pending"
      };
    case "verified":
      return {
        icon: <CheckFillIcon width="16px" height="16px" color="#1d9142" />,
        color: "#1d9142",
        value: "verified",
        disabled: false,
        name: "Verified"
      };
    case "rejected":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color="#d01d23" />,
        color: "#d01d23",
        value: "rejected",
        disabled: false,
        name: "Rejected"
      };
    default:
      return { value: "", disabled: false, color: "#000", name: "N/A" };
  }
}

export function InvoiceStatus(status) {
  switch (status) {
    case "initiated":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color={"#f1a001"} />,
        color: "#f1a001", // Orange
        name: "Initiated"
      };
    case "unpaid":
      return {
        icon: <CheckFillIcon width="16px" height="16px" color="#c44242" />,
        color: "#c44242", // Red
        name: "Unpaid"
      };
    case "paid":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color="#6ba736" />,
        color: "#6ba736",
        name: "Paid"
      };
    case "delivered_unpaid":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color="#d5224f" />,
        color: "#d5224f", // Yellow
        name: "Delivered Unpaid"
      };
    case "completed":
      return {
        icon: <ExclamationFillIcon width="16px" height="16px" color="#038f11" />,
        color: "#038f11", // Green
        name: "Completed"
      };
    default:
      return { color: "#6c757d", name: "N/A" }; // Grey for default
  }
}

export const invoiceStatusOptions = [
  {
    label: "Initiated",
    value: "initiated"
  },
  {
    label: "Paid",
    value: "paid"
  },
  {
    label: "Unpaid",
    value: "unpaid"
  },
  {
    label: "Delivered Unpaid",
    value: "delivered_unpaid"
  },
  {
    label: "Completed",
    value: "completed"
  }
];

export function getCountryCode() {
  if (countryCountryCode[timezoneCountry[moment.tz.guess()]] === "IN") {
    return "IN";
  } else if (countryCountryCode[timezoneCountry[moment.tz.guess()]] === "CA") {
    return "CA";
  } else {
    return "US";
  }
}
export function CodeType(code) {
  switch (code) {
    case "discountCode":
      return { label: "Discount", value: "discountCode", color: "#f5938f" };
    case "promotionCode":
      return { label: "Promotion", value: "promotionCode", color: "#4c8ac7" };
    case "welcomeCode":
      return { label: "First Time", value: "welcomeCode", color: "#47a978" };
    default:
      return { label: "Promotion", value: "promotionCode", color: "#4c8ac7" };
  }
}
export const invoiceOptions = [
  {
    value: "estimate",
    label: "ESTIMATE"
  },
  {
    value: "initiated",
    label: "INITIATED"
  },
  {
    value: "unpaid",
    label: "UNPAID"
  },
  {
    value: "paid",
    label: "PAID"
  },
  {
    value: "delivered_unpaid",
    label: "DELIVERED UNPAID"
  },
  {
    value: "completed",
    label: "COMPLETED"
  }
];
export const PlatformOptions = [
  {
    value: "App",
    label: "Application"
  },
  {
    value: "Web",
    label: "Website"
  },
  {
    value: "WebApp",
    label: "Both (Application and Website)"
  }
];

export function setCookie(name, value) {
  localStorage.setItem(name, value);
  // document.cookie = name + "=" + value + ";path=/";
}
export function setCookiePath(name, value) {
  document.cookie = name + "=" + value + ";path=" + window.location.href;
}
export function getCookie(cname) {
  const value = localStorage.getItem(cname);
  if (value) {
    return value;
  } else {
    return "";
  }
  // let name = cname + "=";
  // let decodedCookie = decodeURIComponent(document?.cookie);
  // let ca = decodedCookie.split(";");
  // for (let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   while (c.charAt(0) === " ") {
  //     c = c.substring(1);
  //   }
  //   if (c.indexOf(name) === 0) {
  //     return c.substring(name.length, c.length);
  //   }
  // }
  // return "";
}
export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function getCountry(countryCode) {
  switch (countryCode) {
    case "US":
      return "USA";
    case "IN":
      return "India";
    case "CA":
      return "Canada";
    default:
      return "USA";
  }
}
export const countriesList = [
  {
    value: "all",
    label: "All"
  },
  {
    value: "US",
    label: "USA"
  },
  {
    value: "CA",
    label: "Canada"
  },
  {
    value: "IN",
    label: "India"
  }
];
export function getTimezone(countryCode) {
  switch (countryCode) {
    case "US":
      return "America/New_York";
    case "IN":
      return "Asia/Calcutta";
    case "CA":
      return "Canada/Central";
    case "AU":
      return "Australia/Melbourne";
    default:
      return "America/New_York";
  }
}
export function scrollBottom() {
  document.querySelector(".respBottomSpace")?.scrollTo(0, document.querySelector(".respBottomSpace")?.scrollHeight);
}

export const inputScroll = (e) => {
  e.target.blur();
  e.stopPropagation();
  setTimeout(() => {
    e.target.focus();
  }, 0);
};
export function sumArrayValues(arr, key) {
  return arr.reduce((acc, obj) => {
    acc += obj[key];
    return acc;
  }, 0);
}

export const paymentGateway = [
  {
    label: "Authorize",
    value: "authorize"
  },
  {
    label: "Phonepe",
    value: "PhonePe"
  },
  {
    label: "Razorpay",
    value: "razorpay"
  },
  {
    label: "Stripe",
    value: "stripe"
  }
];
export function calculateIndex(currentPage, pageSize, itemIndex) {
  return (currentPage - 1) * pageSize + itemIndex;
}

export function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

export const customerType = [
  {
    label: "Customer",
    value: "customer"
  },
  {
    label: "Guest",
    value: "guest"
  },
  {
    label: "Sponsor-promoter",
    value: "sponsor-promoter"
  },
  {
    label: "Window",
    value: "window"
  }
];
export const reportPeriodOption = [
  {
    value: "all",
    name: "reportPeriodOption",
    label: "All Dates"
  },
  {
    value: "custom",
    name: "reportPeriodOption",
    label: "Custom"
  },
  {
    value: "today",
    name: "reportPeriodOption",
    label: "Today"
  },
  {
    value: "this_week",
    name: "reportPeriodOption",
    label: "This Week"
  },
  {
    value: "this_Week_to_date",
    name: "reportPeriodOption",
    label: "This Week To Date"
  },
  {
    value: "this_month_to_date",
    name: "reportPeriodOption",
    label: "This Month To Date"
  },
  {
    value: "previous_month",
    name: "reportPeriodOption",
    label: "Previous Month"
  },
  {
    value: "this_financial_quarter_to_date",
    name: "reportPeriodOption",
    label: "This Financial Quarter-to-date"
  },
  {
    value: "previous_financial_quarter",
    name: "reportPeriodOption",
    label: "Previous Financial Quarter"
  },
  {
    value: "this_calendar_year_to_date",
    name: "reportPeriodOption",
    label: "This Calendar Year-to-date"
  },
  {
    value: "previous_calendar_year",
    name: "reportPeriodOption",
    label: "Previous Calendar Year"
  }
];
export function countryWisePaymentGAtway(country) {
  if (country === "IN") {
    return [
      {
        label: "PhonePe",
        value: "phonepe"
      },
      {
        label: "Razorpay",
        value: "razorpay"
      }
    ];
  } else {
    return [
      {
        label: "Authorize",
        value: "authorize"
      },
      {
        label: "Stripe",
        value: "stripe"
      }
    ];
  }
}
export function capitalize(string = "") {
  if (string) {
    return string.replace(/^./, (str) => str.toUpperCase());
  } else {
    return "";
  }
}

export const dateFormat = "Do MMM YYYY";

export function getDayStartWithTZ(date, TZ = "America/New_York") {
  if (date && date !== undefined) {
    const convertedTime = moment(date).tz(TZ);

    const time = convertedTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return new Date(time);
  }
  return "";
}

export function getDayEndWithTZ(date, TZ = "America/New_York") {
  if (date && date !== undefined) {
    const convertedTime = moment(date).tz(TZ);

    const time = convertedTime.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    return new Date(time);
  }
  return "";
}

export const refunded_status = ["refunded", "pending", "webhookWaiting"];

export const joditButtons = [
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "ul",
  "ol",
  "|",
  "outdent",
  "indent",
  "|",
  "fontsize",
  "brush",
  "paragraph",
  "symbols",
  "classSpan",
  "lineHeight",
  "superscript",
  "subscript",
  "|",
  "table",
  "link",
  "|",
  "align",
  "undo",
  "redo",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "source"
  // "print",
  // "about"
];

export const genderOptions = [
  { value: "male", name: "Male" },
  { value: "female", name: "Female" },
  { value: "other", name: "Other" }
];

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
export function decodedFrontUrl(trailingUrl) {
  let url = `${process.env.REACT_APP_FRONT_URI}/${trailingUrl}`;
  let encoded = encodeURI(url);
  return decodeURI(encoded);
}

export const convertDateTo12 = (startDate, TZ) => {
  return moment(startDate).tz(TZ).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  });
};

export function removeFalsyValues(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}

export const findArrayTotal = (arr, key) => {
  return arr?.reduce((accumulator, currentValue) => accumulator + Number(currentValue[key]), 0);
};

export const findArrObjInnerTotal = (arr, key, objKey) => {
  let value = 0;
  arr?.forEach((element) => {
    value += element?.tickets?.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue[key][objKey]),
      0
    );
  });
  return value;
};

export const findArrObjTotal = (arr, key, objKey) => {
  return arr?.reduce((accumulator, currentValue) => accumulator + Number(currentValue[key][objKey]), 0);
};

export const UUID = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};
export function customRound(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}
export const roundFix = (a) => Math.round(a * 100) / 100;

export const ticketFormats = [
  {
    value: "A4",
    label: "A4"
  },
  {
    value: "horizontal",
    name: "reportPeriodOption",
    label: "Horizontal"
  }
];

export const fetchImageAsBinary = async (file) => {
  try {
    const response = await fetch(API_ROOT + file?.fileUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch the image");
    }
    const arrayBuffer = await response.arrayBuffer();
    const files = new File([arrayBuffer], file?.fileName, { type: file?.contentType });
    return files;
  } catch (error) {
    console.error("Error fetching image:", error);
  }
};

export function compareArrays(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    let obj1 = arr1[i];
    let obj2 = arr2[i];

    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return false;
    }
  }
  return true;
}
